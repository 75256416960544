<template>
  <div>
    <div class="top">
      <img src="../assets/shouye/top.jpg" alt="" />
    </div>
    <div class="introd">
      <div class="introd_title">
        <img src="../assets/shouye/introd/introd_title.jpg" alt="" />
      </div>
      <div class="introd_body">
        <div>
          <img class="suofang" src="../assets/shouye/introd/001.jpg" alt="" />
        </div>
        <div>
          <p class="suofang">
            实现通用管理软件常用模块销售管理、采购管理、仓储管理技术管理、质量管理、计划管理、车间管理、社保管理、应收应付管理、总账管理等等制造业管理软件常用模块
          </p>
        </div>
        <div>
          <img class="suofang" src="../assets/shouye/introd/003.jpg" alt="" />
        </div>
        <div>
          <p class="suofang">
            降低管理软件维护、开发及成本，使管理软件功能更轻量化、更开源、更行业化、更具管理的本性
          </p>
        </div>
        <div>
          <img class="suofang" src="../assets/shouye/introd/002.jpg" alt="" />
        </div>
        <div>
          <p class="suofang">
            不限行业跨度，已成功实现为多个行业的企业提供管理软件，如建筑构件行业、橡胶行业、吹塑行业、薄膜行业、彩印行业、造纸行业等
          </p>
        </div>
      </div>
    </div>
    <div class="fangan">
      <div class="fangan_title">
        <img src="../assets/shouye/fangan/fangan_title.jpg" alt="" />
      </div>
      <div class="fangan_body">
        <div v-for="(item, index) in fanganList" :key="index">
          <img class="suofang" :src="item.Url" alt="" />
          <p>{{ item.title }}</p>
        </div>
      </div>
    </div>
    <div class="descript">
      <img src="../assets/shouye/descript.jpg" alt="" />
    </div>
    <div class="certificate">
      <img src="../assets/shouye/certificate.jpg" alt="" />
    </div>
    <div class="service">
      <img src="../assets/shouye/service/service.jpg" alt="" />
      <div class="service_body">
        <div v-for="(item, index) in serList" :key="index" class="shangyi">
          <img :src="item.Url" alt="" />
          <div>
            <p class="title">{{ item.Title }}</p>
            <p class="descr">{{ item.descript }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="advantage">
      <img src="../assets/shouye/advant/adv_title.jpg" alt="" />
      <div class="advant_body">
        <div class="adv_left">
          <img src="../assets/shouye/advant/1.jpg" alt="" />
        </div>
        <div class="adv_right">
          <div
            class="adv_item"
            v-for="(item, index) in advantList"
            :key="index"
          >
            <img class="adv_item_img" :src="item.Url" alt="" />
            <div class="adv_item_right">
              <p>{{ item.Title }}</p>
              <div class="adv_des">
                <img
                  class="icon"
                  src="../assets/shouye/advant/phone.png"
                  alt=""
                />
                <p>{{ item.descr }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <img src="../assets/shouye/bottom.jpg" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      fanganList: [
        {
          Url: require("../assets/shouye/fangan/个性化定制.png"),
          title: "个性化定制",
        },
        {
          Url: require("../assets/shouye/fangan/移动端.png"),
          title: "移动端功能概览",
        },
        {
          Url: require("../assets/shouye/fangan/建筑业.png"),
          title: "建筑业",
        },
        {
          Url: require("../assets/shouye/fangan/财务.png"),
          title: "财务管理",
        },
        {
          Url: require("../assets/shouye/fangan/印刷包装.png"),
          title: "印刷包装行业",
        },
        {
          Url: require("../assets/shouye/fangan/塑胶制售.png"),
          title: "塑胶制售行业",
        },
        {
          Url: require("../assets/shouye/fangan/薄膜.png"),
          title: "薄膜行业",
        },
        {
          Url: require("../assets/shouye/fangan/机械设备.png"),
          title: "机械设备行业",
        },
        {
          Url: require("../assets/shouye/fangan/食品制售.png"),
          title: "食品制售行业",
        },
        {
          Url: require("../assets/shouye/fangan/光伏光电.png"),
          title: "光伏/光电行业",
        },
        {
          Url: require("../assets/shouye/fangan/家居.png"),
          title: "家居行业",
        },
        {
          Url: require("../assets/shouye/fangan/医疗器械.png"),
          title: "医疗器械（含GSP）",
        },
        {
          Url: require("../assets/shouye/fangan/汽配制售.png"),
          title: "汽配制售行业",
        },
        {
          Url: require("../assets/shouye/fangan/电子元件.png"),
          title: "电子元件行业",
        },
        {
          Url: require("../assets/shouye/fangan/化工材料.png"),
          title: "化工材料/新材料",
        },
        {
          Url: require("../assets/shouye/fangan/新能源.png"),
          title: "新能源行业",
        },
        {
          Url: require("../assets/shouye/fangan/生物制药.png"),
          title: "生物制药行业",
        },
        {
          Url: require("../assets/shouye/fangan/玻璃.png"),
          title: "玻璃行业",
        },
        {
          Url: require("../assets/shouye/fangan/工程项目.png"),
          title: "工程项目管理",
        },
        {
          Url: require("../assets/shouye/fangan/电缆电线.png"),
          title: "电缆电线行业",
        },
      ],
      serList: [
        {
          Url: require("../assets/shouye/service/1.jpg"),
          Title: "审批",
          descript: "实现移动审批功能，对接各类管理软件",
        },
        {
          Url: require("../assets/shouye/service/2.jpg"),
          Title: "动态报表",
          descript: "实现对企业数据的移动统计、分析功能 支持多种BI格式",
        },
        {
          Url: require("../assets/shouye/service/3.jpg"),
          Title: "静态报表",
          descript:
            "实现报表定时采集推送服务 支持独立推送、微信推送、钉钉推送、短信推送、邮箱推送等 支持多BI格式",
        },
        {
          Url: require("../assets/shouye/service/4.jpg"),
          Title: "我的表单",
          descript: "支持企业定制各类移动录入表单 数据本地化、安全更可靠",
        },
      ],
      advantList: [
        {
          Url: require("../assets/shouye/advant/2.jpg"),
          Title: "多终端",
          descr:
            "一套开发方式，一套代码，多端发布，可BS、CS、独立APP、可PDA、可微信小程序、可钉钉、可常见的第三方管理软件",
        },
        {
          Url: require("../assets/shouye/advant/3.jpg"),
          Title: "轻量化",
          descr:
            "打破ERP、CRM、OA、MES等传统软件分类，根据自身需要自由组合集成，实现轻量级应用",
        },
        {
          Url: require("../assets/shouye/advant/4.jpg"),
          Title: "低成本",
          descr: "周期短、技术要求低、模板丰富、适用场景全面，综合成本大大降低",
        },
      ],
    };
  },
};
</script>
<style lang="less" scoped>
.top {
  img {
    width: 100%;
  }
}
.introd {
  background: #f5f5f5;
  .introd_title {
    img {
      width: 100%;
    }
  }
  .introd_body {
    width: 70vw;
    margin: 0 auto;
    padding: 2vw 0;
    display: grid;
    grid-template-columns: repeat(3, 33.33%);
    // grid-template-rows: repeat(2, 50%);
    img {
      width: 100%;
    }
    div {
      font-family: ZhongHei;
      background: #fff;
      width: 100%;
      height: 13.1vw;
      overflow: hidden;
      &:nth-child(2n) {
        font-size: 1.2vw;
        font-weight: 200;
        color: rgb(36, 35, 35);
        p {
          margin: 20px;
          height: calc(100% - 40px);
          overflow: hidden;
        }
      }
    }
  }
}
.fangan {
  background: #f8f8f8;
  .fangan_title {
    img {
      width: 100%;
    }
  }
  .fangan_body {
    width: 67vw;
    margin: 0 auto;
    padding: 3vw 0;
    display: grid;
    grid-template-columns: repeat(5, 20%);
    div {
      margin-bottom: 1vw;
      img {
        width: 45%;
        display: block;
        margin: 0 auto;
      }
      p {
        text-align: center;
        font-family: BOLDO;
        font-size: 1.3vw;
        color: #000;
        margin: 0.8vw 0;
      }
    }
  }
}
.descript {
  img {
    width: 100%;
  }
}
.certificate {
  img {
    width: 100%;
  }
}
.service {
  background: #f5f5f5;
  img {
    width: 100%;
  }
  .service_body {
    display: flex;
    width: 85%;
    margin: 0 auto;
    padding: 3vw 0;
    justify-content: space-between;
    div {
      width: 23.5%;
      background: #fff;
      img {
        width: 100%;
      }
      div {
        width: 100%;
        padding: 1.3vw 2vw;
        .title {
          font-size: 1.5vw;
          font-family: MEDIUMO;
          margin-bottom: 0.5vw;
        }
        .descr {
          font-family: MEDIUMO;
          font-size: 0.8vw;
          -webkit-text-size-adjust: none;
          margin: 0;
        }
      }
    }
  }
}
.advantage {
  img {
    width: 100%;
  }
  .advant_body {
    width: 60%;
    height: 21.3vw;
    margin: 1vw auto;
    display: flex;
    justify-content: space-between;
    .adv_left {
      width: 49%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .adv_right {
      width: 48%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .adv_item {
        height: 30%;
        border-bottom: 2px solid #b9b9b9;
        display: flex;
        .adv_item_img {
          width: 7vw;
          height: 5vw;
        }
        .adv_item_right {
          margin-left: 1vw;
          p {
            font-size: 1vw;
            font-family: BOLDO;
            margin-bottom: 0.5vw;
          }
          .adv_des {
            display: flex;
            .icon {
              width: 1vw;
              height: 1vw;
              margin-right: 0.2vw;
            }
            p {
              font-size: 0.7vw;
              color: #b9b9b9;
              font-family: REGULAR;
              margin: 0;
            }
          }
        }
      }
    }
  }
}
.bottom {
  margin-top: 4vw;
  img {
    width: 100%;
  }
}
.suofang:hover {
  transform: scale(1.05);
  transition-duration: 0.5s;
}
//导入字体样式
@font-face {
  font-family: ZhongHei;
  src: url("../assets/font/时尚中黑简体.TTF");
}
@font-face {
  font-family: BOLDO;
  src: url("../assets/font/BOLD.OTF");
}
@font-face {
  font-family: MEDIUMO;
  src: url("../assets/font/MEDIUM.OTF");
}
@font-face {
  font-family: REGULAR;
  src: url("../assets/font/REGULAR.OTF");
}
.shangyi:hover {
  transform: translateY(-5%);
  transition-duration: 0.5s;
}
.shangyishow {
  animation: showitem 0.5s linear;
}
//上移显示动画
@keyframes showitem {
  0% {
    opacity: 0.5;
    transform: translateY(0);
  }
  100% {
    opacity: 1;
    transform: translateY(-10%);
  }
}
</style>